import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const TZ_ASIA_TOKYO = 'Asia/Tokyo';

/**
 * 指定された日時をISO8601表記に変換（タイムゾーンはJST）
 */
export function toIso8601Jst(date: Date): string {
  const strDateTime = dayjs(date)
    .tz(TZ_ASIA_TOKYO)
    .format('YYYY-MM-DDTHH:mm:ssZ');

  return strDateTime;
}

/**
 * 指定された日の週の月曜0時0分0秒を取得（タイムゾーンはJST）
 */
export function getMondayOfTheWeekJst(date: Date | dayjs.Dayjs): Date {
  // 月曜を週の始まりとして扱いたいが、dayjsは日曜を週の始まりとして扱うという問題あり。
  // そこで、以下の手順をとる。
  // 1. 与えられた日時から1日減算し、
  // 2. 週の始まりを取得し（dayjsが日曜を返す）、
  // 3. 最後に1日加算する。
  return dayjs(date)
    .tz(TZ_ASIA_TOKYO)
    .subtract(1, 'day')
    .startOf('week')
    .add(1, 'day')
    .toDate();
}

/**
 * 指定された日の前週の月曜0時0分0秒を取得（タイムゾーンはJST）
 */
export function getMondayOfLastWeekJst(date: Date | dayjs.Dayjs): Date {
  const oneWeekAgo = dayjs(date).subtract(7, 'day');
  return getMondayOfTheWeekJst(oneWeekAgo);
}

/**
 * 指定された日の週の日曜23時59分59秒を取得（タイムゾーンはJST）
 */
export function getEndOfSundayOfTheWeekJst(date: Date): Date {
  return dayjs(date)
    .tz(TZ_ASIA_TOKYO)
    .endOf('week')
    .add(1, 'day')
    .millisecond(0)
    .toDate();
}

/**
 * 指定された日の0時0分0秒を取得（タイムゾーンはJST）
 */
export function getStartOfTheDayJst(date: Date): Date {
  return dayjs(date).tz(TZ_ASIA_TOKYO).startOf('day').toDate();
}

/**
 * 指定された日が月曜日であればtrueを返す（タイムゾーンはJST）
 */
export function isMonday(date: Date) {
  const numOfMonday = 1;
  const dayOfWeek = dayjs(date).tz(TZ_ASIA_TOKYO).day();
  return dayOfWeek === numOfMonday;
}

/**
 * 指定日時から現在までN時間経過したかどうか
 */
export function isAfterDiffHourSinceTargetDateToCurrentDate(
  diffHour: Number,
  targetDate: Date,
): boolean {
  return dayjs().diff(dayjs(targetDate), 'hours') > diffHour;
}

/**
 * 指定日の23時59分59秒を取得 デフォルトは現在時刻（タイムゾーンはJST）
 */
export function getEndOfTheDayJst(targetDate?: Date): Date {
  if (!targetDate) {
    targetDate = new Date();
  }
  return dayjs(targetDate)
    .tz(TZ_ASIA_TOKYO)
    .endOf('day')
    .millisecond(0)
    .toDate();
}

/**
 * 指定時刻から指定日数前の23時59分59秒を取得 デフォルトは現在時刻（タイムゾーンはJST）
 */
export function getEndOfTheDayBeforeJst(
  diffDate: number,
  targetDate?: Date,
): Date {
  if (!targetDate) {
    targetDate = new Date();
  }
  return dayjs(targetDate)
    .tz(TZ_ASIA_TOKYO)
    .subtract(diffDate, 'day')
    .endOf('day')
    .millisecond(0)
    .toDate();
}

/**
 * 指定時刻から指定日数前の00時00分00秒を取得 デフォルトは現在時刻（タイムゾーンはJST）
 */
export function getStartOfTheDayBeforeJst(
  diffDate: number,
  targetDate?: Date,
): Date {
  if (!targetDate) {
    targetDate = new Date();
  }
  return dayjs(targetDate)
    .tz(TZ_ASIA_TOKYO)
    .subtract(diffDate, 'day')
    .startOf('day')
    .millisecond(0)
    .toDate();
}
