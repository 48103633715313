import { Children, ReactNode } from 'react';
import { FreeMode, Keyboard, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
} from 'swiper/react';

export default function HorizontalScrollList({
  children,
  swiperOptions,
  sliderOptions,
}: {
  children: ReactNode;
  swiperOptions?: SwiperProps;
  sliderOptions?: SwiperSlideProps;
}) {
  return (
    <Swiper
      modules={[Navigation, Keyboard, Mousewheel, FreeMode]}
      keyboard
      navigation
      style={{
        marginLeft: 0,
      }}
      className="scroll-list-swiper"
      slidesPerView={'auto'}
      spaceBetween={20}
      mousewheel={{
        forceToAxis: true,
      }}
      freeMode={{
        enabled: true,
        momentumRatio: 0.5,
      }}
      {...swiperOptions}
    >
      {Children.map(children, (child) => (
        <SwiperSlide
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
          {...sliderOptions}
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
