import NextImage from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Heading,
  IconButton,
  Flex,
  Text,
  Center,
  Button,
  Link,
} from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { useCallback, useEffect, useRef, useState } from 'react';
import useApiClient from '../hooks/useApiClient';
import monitoring from '../common/libs/monitoring';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

export type SlideRequestItemProps = {
  id: number;
  diseaseName: string;
  description: string | null;
  tags: (string | null)[];
  requestCount: number;
  requested?: boolean;
};

export type SlideRequestsUiProps = {
  requestedSlides: SlideRequestItemProps[];
  onClickRequestButton: (id: number) => void;
  onClickPostButton: () => void;
  marginTop: string;
};

const borerColor = '#D8D8D8';
const headingBgColor = '#FAFAFA';
const postedBgColor = '#FFF3CD';

const postedSlides: {
  title: string;
  articleId: string;
}[] = [
  {
    title: '「大動脈弁狭窄症 〜TAVIが普及した時代におけるAS〜」',
    articleId: 'ffe0d94ebcfe4006',
  },
  {
    title: '「結核を学ぼう　多剤耐性結核を知る・意識する・防ぐ」',
    articleId: 'fb40094d578145d6',
  },
];

export function SlideRequestsUi({
  requestedSlides,
  onClickRequestButton,
  onClickPostButton,
  marginTop = '0px', // LayoutにおけるNavBarの高さ
}: SlideRequestsUiProps) {
  const headerRef = useRef<HTMLDivElement>(null);
  const headerRefSize = useSize(headerRef) ?? { height: null };
  const [headerHeight, setHeaderHeight] = useState(120);

  useEffect(() => {
    headerRefSize.height && setHeaderHeight(headerRefSize.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRefSize.height]);

  return (
    <Box
      borderLeft={'1px'}
      borderColor={borerColor}
      backgroundColor={'white'}
      pos={'relative'}
      pt={`${headerHeight}px`}
    >
      <Box
        ref={headerRef}
        textAlign={'center'}
        pos={'absolute'}
        top={0}
        left={0}
        right={0}
        width={'100%'}
        borderBottom={'1px'}
        borderColor={borerColor}
        zIndex={1}
      >
        <Box backgroundColor={headingBgColor} px={2} py={2}>
          <Heading as={'h3'} fontSize={'xl'} p={1}>
            リクエストスライド
          </Heading>
          <Text fontSize={'xs'} lineHeight={1.3}>
            もっと知りたいカテゴリに
            <Text as="span" fontWeight="bold">
              「応援」
            </Text>
            を押してください。
            <br />
            リクエストスライドがリストにない場合は、
            <br />
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLScwfou1JAws_TZqi-K3CnNPglzREY4l8gb0tJtShLy6-T7bhw/viewform"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendAnalyticsEvent(
                  'link_to',
                  'form',
                  'theme_request_from_sidebar',
                );
              }}
            >
              こちら
            </Link>
            よりご記入ください。
            <br />
            ご投稿もお待ちしております。
          </Text>
        </Box>
        <Box
          backgroundColor={postedBgColor}
          borderTop={'1px'}
          borderColor={borerColor}
          p={3}
        >
          <Text fontSize={'xs'} lineHeight={1.3}>
            皆様よりリクエストのスライドが投稿されました。
            <br />
            ありがとうございます。
          </Text>
          <Box pt={1}>
            {postedSlides.map(({ title, articleId }) => (
              <Box key={articleId} pt={1}>
                <Link href={`/article/view/${articleId}`}>
                  <Text fontSize={'xs'} lineHeight={1.3}>
                    {title}
                  </Text>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        overflowY={'scroll'}
        height={{ lg: `calc(100vh - (${headerHeight}px + ${marginTop}))` }}
      >
        {requestedSlides.map(
          ({ id, diseaseName, description, tags, requestCount, requested }) => (
            <Box
              key={id}
              borderBottom={'1px'}
              borderColor={borerColor}
              p={2}
              fontSize={'sm'}
            >
              <Box pl={2} lineHeight={1.1}>
                {tags.map((tag) => (
                  <Text
                    as={'span'}
                    fontSize={'xs'}
                    key={tag}
                    display={'inline'}
                    pr={2}
                  >
                    #{tag}
                  </Text>
                ))}
              </Box>
              <Flex alignItems={'center'}>
                <Box flex={1} pl={2} mr={-2}>
                  <Box pt={1}>
                    <Text as={'h4'} fontWeight={'bold'} pb={1} lineHeight={1.2}>
                      {diseaseName}
                    </Text>
                    <Text fontSize={'xs'} lineHeight={1.3}>
                      {description}
                    </Text>
                  </Box>
                </Box>
                <Center
                  flexDirection={'column'}
                  width={'5em'}
                  justifyContent={'center'}
                >
                  <IconButton
                    aria-label={'応援ボタン'}
                    size={'sm'}
                    variant={'ghost'}
                    icon={
                      <NextImage
                        src={`/images/icon_slide_request_button${
                          requested ? '_active' : ''
                        }.svg`}
                        width={24}
                        height={24}
                        alt={'応援ボタン'}
                      />
                    }
                    onClick={() => onClickRequestButton(id)}
                  />
                  <Text fontSize={'xs'}>応援 {requestCount}</Text>
                </Center>
                <Button
                  fontSize={'xs'}
                  size={'sm'}
                  onClick={onClickPostButton}
                  variant={'ghost'}
                  colorScheme="brand"
                >
                  <Text>投稿する</Text>
                </Button>
              </Flex>
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
}

function useSlideRequests() {
  const { apiClient } = useApiClient();
  const [requestedSlides, setRequestedSlides] = useState<
    SlideRequestItemProps[]
  >([]);
  const fetchRequestedSlides = useCallback(async () => {
    try {
      const res = await apiClient('/api/slide-request', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setRequestedSlides(data);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: 'リクエストスライドの取得に失敗しました。',
      });
    }
  }, [apiClient]);

  const incrementRequestCount = useCallback(
    async (requestedSlideId: number) => {
      try {
        const res = await apiClient(
          `/api/slide-request/${String(requestedSlideId)}/vote`,
          {
            method: 'POST',
          },
        );
        if (res.ok) {
          fetchRequestedSlides();
        } else if (res.status === 403) {
          // デモアカウントの時
          fetchRequestedSlides();
        } else {
          throw new Error();
        }
      } catch (e) {
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: 'リクエストスライドの応援カウント更新に失敗しました。',
        });
      }
    },
    [apiClient, fetchRequestedSlides],
  );

  return {
    requestedSlides,
    setRequestedSlides,
    fetchRequestedSlides,
    incrementRequestCount,
  };
}

export default function SlideRequests({
  marginTop = '0px',
}: {
  marginTop?: string;
}) {
  const router = useRouter();

  const {
    requestedSlides,
    setRequestedSlides,
    fetchRequestedSlides,
    incrementRequestCount,
  } = useSlideRequests();

  const onClickRequestButton: SlideRequestsUiProps['onClickRequestButton'] = (
    id: number,
  ) => {
    setRequestedSlides((prev) =>
      prev.map((slide) => {
        if (slide.id === id) {
          const requested = slide.requested;
          return {
            ...slide,
            requestCount: slide.requestCount + (requested ? -1 : 1),
            requested: !requested,
          };
        }
        return slide;
      }),
    );
    // 先に画面表示を変えてから更新して再取得
    setTimeout(() => incrementRequestCount(id), 1);
  };

  const onClickPostButton: SlideRequestsUiProps['onClickPostButton'] = () => {
    sendAnalyticsEvent('requested_slides', 'click', 'upload');
    router.push('/article/upload');
  };

  useEffect(() => {
    fetchRequestedSlides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlideRequestsUi
      requestedSlides={requestedSlides}
      onClickRequestButton={onClickRequestButton}
      onClickPostButton={onClickPostButton}
      marginTop={marginTop}
    />
  );
}
