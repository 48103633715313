import { Box, Link } from '@chakra-ui/react';
import NextLink, { LinkProps } from 'next/link';
import React from 'react';

// 内部リンク、外部リンクどちらも使えるように
type NextLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps;

// クリッカブルマップにしたい画像の元サイズをpxで指定
type ImageSize = {
  width: number;
  height: number;
};

// クリッカブルマップのリンク領域
// 通常のリンクのpropsに加えて、クリック領域のサイズと位置をpxで指定する
export type ClickableMapAreaProps = NextLinkProps & {
  // クリック領域のタイトルは必須にする
  title: string;
  children?: React.ReactNode;
  // 画像の元サイズに対するクリック領域のサイズをpxで指定
  left: number;
  top: number;
  width: number;
  height: number;
};

type ClickableMapProps = {
  imageSize: ImageSize;
  clickableMapAreas: ClickableMapAreaProps[];
  children?: React.ReactNode;
};

/**
 * クリッカブルマップのようなリンクを作成するコンポーネント
 * 親コンポーネントは position:relative にしておくこと
 * childrenは NextImage にしておくこと
 */
export default function ClickableMap({
  imageSize,
  clickableMapAreas,
  children,
}: ClickableMapProps) {
  return (
    <>
      {children}
      <Box
        position="absolute"
        w="full"
        h="full"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        {clickableMapAreas.map((clickableMapAreaProps, index) => (
          <ClickableMapArea
            key={`${clickableMapAreaProps.title}-${index}`}
            imageSize={imageSize}
            {...clickableMapAreaProps}
          />
        ))}
      </Box>
    </>
  );
}

function ClickableMapArea({
  imageSize,
  left,
  top,
  width,
  height,
  target,
  title,
  children,
  ...restProps
}: ClickableMapAreaProps & {
  imageSize: ImageSize;
}) {
  return (
    <NextLink {...restProps} passHref>
      <Link
        target={target}
        title={title}
        cursor="pointer"
        position="absolute"
        left={`${(left / imageSize.width) * 100}%`}
        top={`${(top / imageSize.height) * 100}%`}
        width={`${(width / imageSize.width) * 100}%`}
        height={`${(height / imageSize.height) * 100}%`}
        opacity={1}
        color="transparent"
        overflow="hidden"
      >
        {children ?? title}
      </Link>
    </NextLink>
  );
}
