import NextLink from 'next/link';
import { Button, ButtonProps } from '@chakra-ui/react';

function ViewAllLInk({
  href,
  onClick,
  label = 'すべて見る',
  additionalButtonProps,
}: {
  href: string;
  onClick?: () => void;
  label?: string;
  additionalButtonProps?: ButtonProps;
}) {
  return (
    <NextLink href={href} passHref>
      <Button
        as="a"
        w="100%"
        mt={4}
        bg="white.500"
        color="brand.500"
        height={4}
        fontSize={14}
        _hover={{
          bg: 'white.500',
          textDecoration: 'underline',
        }}
        onClick={() => onClick?.()}
        {...additionalButtonProps}
      >
        {label}
      </Button>
    </NextLink>
  );
}

export default ViewAllLInk;
