import { Link, LinkProps } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import getConfig from '../common/config';

type Props = Omit<LinkProps, 'isExternal' | 'href'> & {
  href: string;
  isExternal?: boolean;
  withIcon?: boolean;
};

function ExternalLink({
  href,
  isExternal = true,
  withIcon = true,
  children,
  ...restProps
}: Props) {
  const {
    publicRuntimeConfig: { slideWebBaseUrl },
  } = getConfig();
  const isExternalUrl = !href.startsWith(slideWebBaseUrl);

  return isExternalUrl ? (
    <Link href={href} isExternal={isExternal} {...restProps}>
      {children}
      {withIcon && <ExternalLinkIcon mx={0.5} />}
    </Link>
  ) : (
    <Link href={href} {...restProps}>
      {children}
      {withIcon && <ExternalLinkIcon mx={0.5} />}
    </Link>
  );
}

export default ExternalLink;
